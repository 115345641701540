<template>
  <div class="table-section" style="margin-left:30px;margin-right:30px;">
    <h2><center>Category</center></h2>
    <br/><br/>
    <categories-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />
    <b-card
      header="Category List"
      header-tag="header"
      class="text-center"

    >
    <div class="m-2" >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
        <label>Show</label>
        <v-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>

        </b-col>
        <b-col
          cols="12"
          md="6"
        >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
          <b-button
            variant="primary"
            @click="isAddNewUserSidebarActive = true"
          >
            <span class="text-nowrap">Add Category</span>
          </b-button>
        </div>

      </b-col>

      </b-row>
    </div>
    <div v-if="isEditCategory">
      <b-modal
        ref="refEditCategory"
        title="Edit Category!!"
        v-model="modalShow"
        @hidden="resetModal"
        @ok="updateCategories"
        ok-title="Save"
        ok-only

      >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          id="input-group-category-name"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="editCategoryState"
        >
          <b-form-input
            id="name-input"
            v-model="editCategoryName"
            :state="editCategoryState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
        </b-modal>
    </div>
    <b-table
      ref="refCategoyListTable"
      class="position-relative"
      :items="categories"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
    <template #cell(name)="data">
      <div class="text-nowrap">
        {{ data.item.name }}
      </div>
    </template>
    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >

        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>


        <b-dropdown-item @click="editCategory(data.item)">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>

        <b-dropdown-item @click="deleteCategory(data.item.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>

  </b-table>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">Showing {{from }} to {{ to }} of {{ totalCategories }} entries</span>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalCategories"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-col>
    </b-row>
  </div>

  </b-card>


  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import CategoriesListAddNew from './CategoriesListAddNew.vue'
import { mapMutations, mapActions, mapState } from "vuex";
import BCardCode from '@core/components/b-card-code'

export default {
  name: "categoryList",
  data() {
    return {
      perPage:10,
      currentPage : 1,
      perPageOptions : [10, 25, 50, 100],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
      fetchCategories:[],
      tableColumns :[
        { key: 'name'},
        { key: 'actions' }
      ],
      refCategoyListTable:'categories',
      refEditCategory:'editcategories',
      skip:0,
      from:0,
      to:0,
      isEditCategory:false,
      selectedCategory:null,
      editCategoryName:"",
      editCategoryState:null,
      modalShow: false
    }
  },
  mounted (){
    var query = {
      skip:0,
      limit:this.perPage
    }
    this.loadCategories(query)
  },
  computed:{
    ...mapState({
      categories :(state) =>{

       return state.categories.categories;
     },
     totalCategories: (state) =>{
       console.log("totalCategories =="+JSON.stringify(state.categories.totalCategories))
       return state.categories.totalCategories;
     }
    })
  },
  watch:{
    currentPage:{
      handler(){
        this.skip = (this.currentPage - 1) * this.perPage
        this.reload()
      }
    },
    perPage:{
      handler(){
        this.skip = (this.currentPage - 1) * this.perPage
        this.reload()
      }
    },
    categories:{
      handler(){
        const categoriesLength = this.categories.length;
        this.from = this.perPage * (this.currentPage - 1) + (categoriesLength ? 1 : 0);
        this.to =  this.perPage * (this.currentPage - 1) + categoriesLength;
      }
    }
  },
  methods:{
    ...mapActions({
      loadCategories:"categories/fetchAllCategories",
      deleteCategory:"categories/deleteCategory",
      updateCategoryName:"categories/editCategory"
    }),
    reload(){
      this.loadCategories({skip:this.skip,limit:this.perPage})
    },
    editCategory(category){
      this.isEditCategory = true;
      this.selectedCategory = category;
      this.editCategoryName = category.name
      //this.$refs['refEditCategory'].show()
      this.modalShow = true
      console.log("selected edit category =="+JSON.stringify(category));
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.editCategoryState = valid
        return valid
      },
      resetModal() {
        this.selectedCategory = null
        this.editCategoryState = null
      },
      updateCategories(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        this.updateCategoryName({id:this.selectedCategory.id,name:this.editCategoryName})
        this.$nextTick(() => {
          this.modalShow = false
        })
      }
  },
  components: {
    CategoriesListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BPopover,
    vSelect,
    BCardCode
  },

}

</script>
<style>
.card-header {
  background-color:#ebe9f1;
}
</style>
